.img-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.img-container img {
    margin: auto;
    width: 100%;
    height: auto;
    max-width: 700px;
}
